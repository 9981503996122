// import {Headers, Response, URLSearchParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {Md5} from 'ts-md5/dist/md5';

/* tslint:disable:no-unused-variable member-ordering */

import {map, catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {API_SERVER} from "../../global.config";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // protected basePath = globals.REST_URL + '/api/v1';
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': 'my-auth-token'
    })
  };


  constructor(private  httpClient: HttpClient,private router: Router,private cookieService: CookieService) {
  }


  private build_url(link) {
    return location.origin + '/api/v1/' + link ;
  }

  public logout(){
    this.cookieService.delete('user');
    this.cookieService.delete('username');
    this.router.navigate(['auth','login']);

  }

  public add_user_cookie(user:string){
    this.cookieService.set("username",user)
  }

  public get_user(){
    return this.cookieService.get("username");
  }


  public get_user_avatar(){
    let username = decodeURI(this.get_user());
    let hash = Md5.hashStr(username);
    return "https://www.gravatar.com/avatar/"+hash
  }


  public login(username:string, password:string) : Observable<any> {
    let body = {
      'username': username,
      'password': Md5.hashStr(password),
    };
    return this.httpClient.post(this.build_url('login'), JSON.stringify(body), this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'login'))
      );
  }

  public modify_password(old_password:string, new_password:string) : Observable<any> {
    let body = {
      'password': Md5.hashStr(old_password),
      'new_password': Md5.hashStr(new_password),
    };
    return this.httpClient.post(this.build_url('update_pw'), JSON.stringify(body), this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'modify_password'))
      );
  }

  public modify_api(password:string, exchange:string, api_key:string, api_secret:string,margin:boolean, leverage:number) : Observable<any> {
    let body = {
      'password': Md5.hashStr(password),
      'exchange':exchange,
      'api_token': api_key,
      'api_secret':api_secret
    };
    if(margin===true){
      body["margin"]=true;
      body["leverage"]=leverage;
    }
    return this.httpClient.post(this.build_url('update_api'), JSON.stringify(body), this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'modify_api'))
      );
  }


  public modify_info(password:string, enabled:boolean,margin:boolean, leverage:number, build:number, pair:string) : Observable<any> {
    let body = {
      'password': Md5.hashStr(password),
      'enabled':enabled,
      'build': build,
      'pair':pair,
    };
    if(margin===true){
      body["margin"]=true;
      body["leverage"]=leverage;
    }
    return this.httpClient.post(this.build_url('update_info'), JSON.stringify(body), this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'modify_api'))
      );
  }




  public get_trades() : Observable<any> {
    let body ={};
    return this.httpClient.get(this.build_url('trades'),this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'trades'))
      );
  }
  public get_profit() : Observable<any> {
    let body ={};
    return this.httpClient.get(this.build_url('profit'),this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'trades'))
      );
  }

  public get_history() : Observable<any> {
    let body ={};
    return this.httpClient.get(this.build_url('history'),this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'trades'))
      );
  }

  public get_exchanges() : Observable<any> {
    let body ={};
    return this.httpClient.get(this.build_url('exchanges'),this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'trades'))
      );
  }

  public get_info() : Observable<any> {
    let body ={};
    return this.httpClient.get(this.build_url('userinfo'),this.httpOptions)
      .pipe(
        catchError(err => this.handleError(err, 'trades'))
      );
  }




  // public modify_service(id, body): Observable<any> {
  //   return this.httpClient.put(this.build_url('services/' + id),
  //     JSON.stringify(body), this.httpOptions)
  //     .pipe(
  //       catchError(err => this.handleError(err, 'modifyService:' + id))
  //     );
  //
  // }


  private handleError(error, operation) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(`An error occurred when doing ${operation}:`, error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      if(error.status==401){
        this.router.navigate(['auth','login']);
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


  // /**
  //  * Add/Activate the firewall Rule.
  //  * @param body the name.
  //  */
  // public firewallPost (body: any, extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/firewall';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.defaultHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling datasourcePut.');
  //   }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'POST',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //   requestOptions.body = JSON.stringify(body);
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  //
  // /**
  //  * Get the firewall status.
  //  */
  // public firewallGet (extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/firewall';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.getHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   // if (body === null || body === undefined) {
  //   //   throw new Error('Required parameter body was null or undefined when calling datasourcePut.');
  //   // }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'GET',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  // /**
  //  * Disable the firewall.
  //  */
  //
  // public firewallDelete (extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/firewall';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.getHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   // if (body === null || body === undefined) {
  //   //   throw new Error('Required parameter body was null or undefined when calling datasourcePut.');
  //   // }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'DELETE',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  //
  // /**
  //  * Add/Activate the AppRoute Rule.
  //  * @param body the name.
  //  */
  // public appRoutePost (body: any, extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/app_route';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.defaultHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling datasourcePut.');
  //   }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'POST',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //   requestOptions.body = JSON.stringify(body);
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  //
  // /**
  //  * Get the approute status.
  //  */
  // public appRouteGet (extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/app_route';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.getHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   // if (body === null || body === undefined) {
  //   //   throw new Error('Required parameter body was null or undefined when calling datasourcePut.');
  //   // }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'GET',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  // /**
  //  * Disable the firewall.
  //  */
  //
  // public appRouteDelete (extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/app_route';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.getHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   // if (body === null || body === undefined) {
  //   //   throw new Error('Required parameter body was null or undefined when calling datasourcePut.');
  //   // }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'DELETE',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  //
  // /**
  //  * Get the device list.
  //  */
  // public deviceGet (extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/devices';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.getHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   // if (body === null || body === undefined) {
  //   //   throw new Error('Required parameter body was null or undefined when calling datasourcePut.');
  //   // }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'GET',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  //
  // /**
  //  * Get the device Intf list.
  //  */
  // public deviceIntfGet (intf_name: string,extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/devices/' + intf_name;
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.getHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   // if (body === null || body === undefined) {
  //   //   throw new Error('Required parameter body was null or undefined when calling datasourcePut.');
  //   // }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'GET',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  //
  // /**
  //  * Get the traffic of the intf.
  //  * @param body including device and intf.
  //  */
  // public trafficPost (body: any, extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/traffic';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.defaultHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling trafficPost.');
  //   }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'POST',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //   requestOptions.body = JSON.stringify(body);
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }
  //
  //
  // /**
  //  * Get the app_vis of the intf.
  //  * @param body including device.
  //  */
  // public appvisPost (body: any, extraHttpRequestParams?: any ) : Observable<Array<any>> {
  //   const path = this.basePath + '/app_vis';
  //
  //   let queryParameters = new URLSearchParams();
  //   let headerParams = this.defaultHeaders;
  //   // verify required parameter 'body' is not null or undefined
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling trafficPost.');
  //   }
  //   let requestOptions: RequestOptionsArgs = {
  //     method: 'POST',
  //     headers: headerParams,
  //     search: queryParameters
  //   };
  //   requestOptions.body = JSON.stringify(body);
  //
  //   return this.http.request(path, requestOptions)
  //     .map((response: Response) => {
  //       if (response.status === 204) {
  //         return undefined;
  //       } else {
  //         return response.json();
  //       }
  //     });
  // }


}
